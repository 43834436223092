const URL = {
  testbed: {
    apiUrl: "https://test999.examdesk.co/api/public/v1/examPanel",
    examdeskApiUrl: "http://test999.examdesk.co",
    webUrl: "http://testpanel999.examdesk.co",
    procturUrl: "https://test999.proctur.com/StdMgmtWebAPI/api/v1",
  },
  prod: {
    apiUrl: "https://loadapi.proctur.com/v1/examPanel",
    examdeskApiUrl: "https://loadapi.proctur.com/",
    webUrl: "http://onlinetest.eduims.com",
    procturUrl: "https://api.proctur.com/StdMgmtWebAPI/api/v1",
  },
  uat: {
    apiUrl: "https://onlinetestapi.proctur.com/v1/examPanel",
    examdeskApiUrl: "https://onlinetestapi.proctur.com/",
    webUrl: "https://testuat.eduims.com/",
    procturUrl: "https://uat.proctur.com/StdMgmtWebAPI/api/v1",
  },
  sit: {
    apiUrl: "https://onlinetestsitapi.proctur.com/v1/examPanel",
    examdeskApiUrl: "https://onlinetestsitapi.proctur.com",
    webUrl: "https://onlinetestsitapi.proctur.com",
    procturUrl: "https://apiv2.proctur.com/StdMgmtWebAPI/api/v1",
  },
};
export default URL;
