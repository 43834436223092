import React, { useEffect, useState } from "react";
import "./questionpanel.css";
import QuestionList from "../question_list";
import useWindowDimensions from "../../../services/useWindowDimensions";
import { connect } from "react-redux";
import saveAndNext from "../../../services/saveAndNext";
import Confirm from "../../../components/confirm";
import MandatoryQuestion from "../../../components/mandatory_question_popup";
import changeLanguage from "../../../assets/icons/changeLanguage.svg";
import Slider from "../../../components/Slider";

const QuestionPanel = (props) => {
  const [subjectid, setSubjectId] = useState(
    props?.state?.test_data?.is_neet_test == 1
      ? props.state.selectedSection
      : props.ques_data[props.state.activeQuesInd].subject_id
  );
  const { width } = useWindowDimensions();
  const [show, setShow] = useState(false);
  const [showMadetry, setshowMadetry] = useState(false);

  useEffect(() => {
    if (
      props?.state?.test_data?.is_neet_test == 1 &&
      props.state.selectedSection !== undefined &&
      props.state.selectedSection != "undefined"
    ) {
      setSubjectId(props.state.selectedSection);
    }
  }, [props.state.selectedSection]);

  useEffect(() => {
    if (props?.state?.test_data?.is_neet_test == 1) {
      if (subjectid !== undefined && subjectid != "undefined") {
        if (
          subjectid !=
          "" +
            props.ques_data[props.state.activeQuesInd].subject_id +
            "#" +
            props.ques_data[props.state.activeQuesInd].section_id
        ) {
          props.dispatch({
            type: "ACTIVE_QUESTION_NUMBER",
            activeQuesInd: props.ques_data.findIndex(
              (obj) => "" + obj.subject_id + "#" + obj.section_id == subjectid
            ),
          });
        }
      }
    } else {
      if (subjectid !== props.ques_data[props.state.activeQuesInd].subject_id) {
        props.dispatch({
          type: "ACTIVE_QUESTION_NUMBER",
          activeQuesInd: props.ques_data.findIndex(
            (obj) => obj.subject_id == subjectid
          ),
        });
      }
    }
  }, [subjectid]);

  useEffect(() => {
    if (
      props?.state?.test_data?.is_neet_test == 1 &&
      props?.state?.test_data?.is_sec_time == 1
    ) {
      setSubjectId(props.state.selectedSection);
    } else if (
      props?.state?.test_data?.is_neet_test == 1 &&
      props?.state?.test_data?.is_sec_time == 0
    ) {
      setSubjectId(
        "" +
          props.ques_data[props.state.activeQuesInd].subject_id +
          "#" +
          props.ques_data[props.state.activeQuesInd].section_id
      );
    } else {
      setSubjectId(props.ques_data[props.state.activeQuesInd].subject_id);
    }
  }, [props.state.activeQuesInd]);

  const SavendNext = async () => {
    if (props?.testPanelData?.is_neet_test == 1) {
      let count = 0;
      let attemtedQus = props?.state?.subjects_data.filter((item, index) => {
        return "" + item?.subject_id + "#" + item?.section_id == subjectid;
      });
      let quest = attemtedQus[0]?.questions?.map((item, index) => {
        return item?.ques_id;
      });
      for (let x of props?.state?.questionstatus) {
        if (
          quest.includes(Number(x?.ques_id)) &&
          (x?.status_number == 4 || x?.status_number == 5)
        ) {
          count++;
        }
      }

      if (
        count + 1 > attemtedQus[0]?.attempt_questions &&
        props?.state?.questionstatus.find((item, index) => {
          return item?.ques_id == props?.activeQuesData?.ques_id;
        }).status_number != 4 &&
        props?.state?.questionstatus.find((item, index) => {
          return item?.ques_id == props?.activeQuesData?.ques_id;
        }).status_number != 5
      ) {
        alert(
          `The maximum number of attemptable questions for this section is ${attemtedQus[0]?.attempt_questions}. To attempt this question please un-answer other questions from this section or move to the next section if available, otherwise submit the test`
        );
      } else {
        if (props.state.answers_json[props.state.activeQuesInd] !== "-1") {
          if (props.activeQuesData.ques_type_id == 2) {
            let index = props.state.answers_json[props.state.activeQuesInd]
              .split("_")
              .pop();
            if (props.state.subjective_answer[index] != "-1") {
              let questionStatus = props.state.questionstatus;
              if (
                questionStatus[props.state.activeQuesInd].ques_id ==
                  props.activeQuesData.ques_id &&
                questionStatus[props.state.activeQuesInd].status_number != 4
              ) {
                questionStatus[props.state.activeQuesInd].status_number = 4;
              }
              props.dispatch({
                type: "CHANGES_STATUS",
                questionstatus: [...questionStatus],
              });
              await saveAndNext(props);
              if (
                props?.testPanelData?.is_neet_test == 1 &&
                props?.testPanelData?.is_sec_time == 1
              ) {
                if (
                  "" +
                    props?.state?.ques_data[props.state?.activeQuesInd + 1]
                      ?.subject_id +
                    "#" +
                    props?.state?.ques_data[props.state?.activeQuesInd + 1]
                      ?.section_id ==
                  props?.state?.selectedSection
                ) {
                  props.dispatch({
                    type: "ACTIVE_QUESTION_NUMBER",
                    activeQuesInd: props.state.activeQuesInd + 1,
                  });
                }
              } else {
                await props.dispatch({
                  type: "ACTIVE_QUESTION_NUMBER",
                  activeQuesInd: props.state.activeQuesInd + 1,
                });
              }
            } else if (
              props.testPanelData.test_settings.is_all_mandatory_questions === 1
            ) {
              setshowMadetry(true);
            } else {
              let questionStatus = props.state.questionstatus;
              questionStatus[props.state.activeQuesInd + 1].status_number = 2;
              await props.dispatch({
                type: "CHANGES_STATUS",
                questionstatus: [...questionStatus],
              });
              await saveAndNext(props);
              if (
                props?.testPanelData?.is_neet_test == 1 &&
                props?.testPanelData?.is_sec_time == 1
              ) {
                if (
                  "" +
                    props?.state?.ques_data[props.state?.activeQuesInd + 1]
                      ?.subject_id +
                    "#" +
                    props?.state?.ques_data[props.state?.activeQuesInd + 1]
                      ?.section_id ==
                  props?.state?.selectedSection
                ) {
                  props.dispatch({
                    type: "ACTIVE_QUESTION_NUMBER",
                    activeQuesInd: props.state.activeQuesInd + 1,
                  });
                }
              } else {
                await props.dispatch({
                  type: "ACTIVE_QUESTION_NUMBER",
                  activeQuesInd: props.state.activeQuesInd + 1,
                });
              }
            }
          } else {
            let questionStatus = props.state.questionstatus;
            if (
              questionStatus[props.state.activeQuesInd].ques_id ==
                props.activeQuesData.ques_id &&
              questionStatus[props.state.activeQuesInd].status_number != 4
            ) {
              questionStatus[props.state.activeQuesInd].status_number = 4;
            }
            props.dispatch({
              type: "CHANGES_STATUS",
              questionstatus: [...questionStatus],
            });
            await saveAndNext(props);
            if (
              props?.testPanelData?.is_neet_test == 1 &&
              props?.testPanelData?.is_sec_time == 1
            ) {
              if (
                "" +
                  props?.state?.ques_data[props.state?.activeQuesInd + 1]
                    ?.subject_id +
                  "#" +
                  props?.state?.ques_data[props.state?.activeQuesInd + 1]
                    ?.section_id ==
                props?.state?.selectedSection
              ) {
                props.dispatch({
                  type: "ACTIVE_QUESTION_NUMBER",
                  activeQuesInd: props.state.activeQuesInd + 1,
                });
              }
            } else {
              await props.dispatch({
                type: "ACTIVE_QUESTION_NUMBER",
                activeQuesInd: props.state.activeQuesInd + 1,
              });
            }
          }
        } else if (
          props.testPanelData.test_settings.is_all_mandatory_questions === 1
        ) {
          setshowMadetry(true);
        } else {
          let questionStatus = props.state.questionstatus;
          questionStatus[props.state.activeQuesInd + 1].status_number = 2;
          await props.dispatch({
            type: "CHANGES_STATUS",
            questionstatus: [...questionStatus],
          });
          await saveAndNext(props);
          if (
            props?.testPanelData?.is_neet_test == 1 &&
            props?.testPanelData?.is_sec_time == 1
          ) {
            if (
              "" +
                props?.state?.ques_data[props.state?.activeQuesInd + 1]
                  ?.subject_id +
                "#" +
                props?.state?.ques_data[props.state?.activeQuesInd + 1]
                  ?.section_id ==
              props?.state?.selectedSection
            ) {
              props.dispatch({
                type: "ACTIVE_QUESTION_NUMBER",
                activeQuesInd: props.state.activeQuesInd + 1,
              });
            }
          } else {
            await props.dispatch({
              type: "ACTIVE_QUESTION_NUMBER",
              activeQuesInd: props.state.activeQuesInd + 1,
            });
          }
        }
      }
    } else {
      if (props.state.answers_json[props.state.activeQuesInd] !== "-1") {
        if (props.activeQuesData.ques_type_id == 2) {
          let index = props.state.answers_json[props.state.activeQuesInd]
            .split("_")
            .pop();
          if (props.state.subjective_answer[index] != "-1") {
            let questionStatus = props.state.questionstatus;
            if (
              questionStatus[props.state.activeQuesInd].ques_id ==
                props.activeQuesData.ques_id &&
              questionStatus[props.state.activeQuesInd].status_number != 4
            ) {
              questionStatus[props.state.activeQuesInd].status_number = 4;
            }
            props.dispatch({
              type: "CHANGES_STATUS",
              questionstatus: [...questionStatus],
            });
            await saveAndNext(props);
            await props.dispatch({
              type: "ACTIVE_QUESTION_NUMBER",
              activeQuesInd: props.state.activeQuesInd + 1,
            });
          } else if (
            props.testPanelData.test_settings.is_all_mandatory_questions === 1
          ) {
            setshowMadetry(true);
          } else {
            let questionStatus = props.state.questionstatus;
            questionStatus[props.state.activeQuesInd + 1].status_number = 2;
            await props.dispatch({
              type: "CHANGES_STATUS",
              questionstatus: [...questionStatus],
            });
            await saveAndNext(props);
            await props.dispatch({
              type: "ACTIVE_QUESTION_NUMBER",
              activeQuesInd: props.state.activeQuesInd + 1,
            });
          }
        } else {
          let questionStatus = props.state.questionstatus;
          if (
            questionStatus[props.state.activeQuesInd].ques_id ==
              props.activeQuesData.ques_id &&
            questionStatus[props.state.activeQuesInd].status_number != 4
          ) {
            questionStatus[props.state.activeQuesInd].status_number = 4;
          }
          props.dispatch({
            type: "CHANGES_STATUS",
            questionstatus: [...questionStatus],
          });
          await saveAndNext(props);
          await props.dispatch({
            type: "ACTIVE_QUESTION_NUMBER",
            activeQuesInd: props.state.activeQuesInd + 1,
          });
        }
      } else if (
        props.testPanelData.test_settings.is_all_mandatory_questions === 1
      ) {
        setshowMadetry(true);
      } else {
        let questionStatus = props.state.questionstatus;
        questionStatus[props.state.activeQuesInd + 1].status_number = 2;
        await props.dispatch({
          type: "CHANGES_STATUS",
          questionstatus: [...questionStatus],
        });
        await saveAndNext(props);
        await props.dispatch({
          type: "ACTIVE_QUESTION_NUMBER",
          activeQuesInd: props.state.activeQuesInd + 1,
        });
      }
    }
  };
  const Save = async () => {
    if (props?.testPanelData?.is_neet_test == 1) {
      let count = 0;
      let attemtedQus = props?.state?.subjects_data.filter((item, index) => {
        return "" + item?.subject_id + "#" + item?.section_id == subjectid;
      });
      let quest = attemtedQus[0]?.questions?.map((item, index) => {
        return item?.ques_id;
      });
      for (let x of props?.state?.questionstatus) {
        if (
          quest.includes(Number(x?.ques_id)) &&
          (x?.status_number == 4 || x?.status_number == 5)
        ) {
          count++;
        }
      }

      if (count + 1 > attemtedQus[0]?.attempt_questions) {
        alert(
          `The maximum number of attemptable questions for this section is ${attemtedQus[0]?.attempt_questions}. To attempt this question please un-answer other questions from this section or move to the next section if available, otherwise submit the test`
        );
      } else {
        if (props.state.answers_json[props.state.activeQuesInd] !== "-1") {
          if (props.activeQuesData.ques_type_id == 2) {
            let index = props.state.answers_json[props.state.activeQuesInd]
              .split("_")
              .pop();
            if (props.state.subjective_answer[index] != "-1") {
              let questionStatus = props.state.questionstatus;
              if (
                questionStatus[props.state.activeQuesInd].ques_id ==
                  props.activeQuesData.ques_id &&
                questionStatus[props.state.activeQuesInd].status_number != 4
              ) {
                questionStatus[props.state.activeQuesInd].status_number = 4;
              }
              props.dispatch({
                type: "CHANGES_STATUS",
                questionstatus: [...questionStatus],
              });
              await saveAndNext(props);
              await setShow(true);
            } else if (
              props.testPanelData.test_settings.is_all_mandatory_questions === 1
            ) {
              setshowMadetry(true);
            } else {
              let questionStatus = props.state.questionstatus;
              questionStatus[props.state.activeQuesInd + 1].status_number = 2;
              await props.dispatch({
                type: "CHANGES_STATUS",
                questionstatus: [...questionStatus],
              });
              await saveAndNext(props);
              await setShow(true);
            }
          } else {
            let questionStatus = props.state.questionstatus;
            if (
              questionStatus[props.state.activeQuesInd].ques_id ==
                props.activeQuesData.ques_id &&
              questionStatus[props.state.activeQuesInd].status_number != 4
            ) {
              questionStatus[props.state.activeQuesInd].status_number = 4;
            }
            props.dispatch({
              type: "CHANGES_STATUS",
              questionstatus: [...questionStatus],
            });
            await saveAndNext(props);
            await setShow(true);
          }
        } else if (
          props.testPanelData.test_settings.is_all_mandatory_questions === 1
        ) {
          setshowMadetry(true);
        } else {
          let questionStatus = props.state.questionstatus;
          questionStatus[props.state.activeQuesInd + 1].status_number = 2;
          await props.dispatch({
            type: "CHANGES_STATUS",
            questionstatus: [...questionStatus],
          });
          await saveAndNext(props);
          await setShow(true);
        }
      }
    } else {
      if (
        props.state.answers_json[props.state.activeQuesInd] !== "-1" &&
        !props.state.answers_json.some((value) => value == "-1")
      ) {
        if (props.activeQuesData.ques_type_id == 2) {
          let index = props.state.answers_json[props.state.activeQuesInd]
            .split("_")
            .pop();
          if (props.state.subjective_answer[index] != "-1") {
            let questionStatus = props.state.questionstatus;
            if (
              questionStatus[props.state.activeQuesInd].ques_id ==
                props.activeQuesData.ques_id &&
              questionStatus[props.state.activeQuesInd].status_number != 4
            ) {
              questionStatus[props.state.activeQuesInd].status_number = 4;
            }
            props.dispatch({
              type: "CHANGES_STATUS",
              questionstatus: [...questionStatus],
            });
            await saveAndNext(props);
            await setShow(true);
          } else if (
            props.testPanelData.test_settings.is_all_mandatory_questions === 1
          ) {
            setshowMadetry(true);
          } else {
            let questionStatus = props.state.questionstatus;
            questionStatus[props.state.activeQuesInd + 1].status_number = 2;
            await props.dispatch({
              type: "CHANGES_STATUS",
              questionstatus: [...questionStatus],
            });
            await saveAndNext(props);
            await setShow(true);
          }
        } else {
          let questionStatus = props.state.questionstatus;
          if (
            questionStatus[props.state.activeQuesInd].ques_id ==
              props.activeQuesData.ques_id &&
            questionStatus[props.state.activeQuesInd].status_number != 4
          ) {
            questionStatus[props.state.activeQuesInd].status_number = 4;
          }
          props.dispatch({
            type: "CHANGES_STATUS",
            questionstatus: [...questionStatus],
          });
          await saveAndNext(props);
          await setShow(true);
        }
      } else if (
        props.testPanelData.test_settings.is_all_mandatory_questions === 1
      ) {
        setshowMadetry(true);
      } else {
        let questionStatus = props.state.questionstatus;
        questionStatus[props.state.activeQuesInd + 1].status_number = 2;
        await props.dispatch({
          type: "CHANGES_STATUS",
          questionstatus: [...questionStatus],
        });
        await saveAndNext(props);
        await setShow(true);
      }
    }
  };
  const MarkedForReview = async () => {
    if (props?.testPanelData?.is_neet_test == 1) {
      let count = 0;
      let attemtedQus = props?.state?.subjects_data.filter((item, index) => {
        return "" + item?.subject_id + "#" + item?.section_id == subjectid;
      });
      let quest = attemtedQus[0]?.questions?.map((item, index) => {
        return item?.ques_id;
      });
      for (let x of props?.state?.questionstatus) {
        if (
          quest.includes(Number(x?.ques_id)) &&
          (x?.status_number == 4 || x?.status_number == 5)
        ) {
          count++;
        }
      }
      if (
        count + 1 > attemtedQus[0]?.attempt_questions &&
        props?.state?.questionstatus.find((item, index) => {
          return item?.ques_id == props?.activeQuesData?.ques_id;
        }).status_number != 4 &&
        props?.state?.questionstatus.find((item, index) => {
          return item?.ques_id == props?.activeQuesData?.ques_id;
        }).status_number != 5
      ) {
        alert(
          `The maximum number of attemptable questions for this section is ${attemtedQus[0]?.attempt_questions}. To attempt this question please un-answer other questions from this section or move to the next section if available, otherwise submit the test`
        );
      } else {
        let arr = Array.isArray(props.state.answers_json)
          ? props.state.answers_json
          : props.state.answers_json.split(",");
        let ind = props.state.activeQuesInd;
        if (arr[ind] === "-1" || arr[ind] === -1) {
          let questionStatus = props.state.questionstatus;
          questionStatus[ind].status_number = 3;
          await props.dispatch({
            type: "CHANGES_STATUS",
            questionstatus: [...questionStatus],
          });
        } else {
          let questionStatus = props.state.questionstatus;
          questionStatus[ind].status_number = 5;
          props.dispatch({
            type: "CHANGES_STATUS",
            questionstatus: [...questionStatus],
          });
        }
        await saveAndNext(props);
        if (props.state.activeQuesInd + 1 < props.state.ques_data.length) {
          await props.dispatch({
            type: "ACTIVE_QUESTION_NUMBER",
            activeQuesInd: props.state.activeQuesInd + 1,
          });
        }
      }
    } else {
      let arr = Array.isArray(props.state.answers_json)
        ? props.state.answers_json
        : props.state.answers_json.split(",");
      let ind = props.state.activeQuesInd;
      if (arr[ind] === "-1" || arr[ind] === -1) {
        let questionStatus = props.state.questionstatus;
        questionStatus[ind].status_number = 3;
        await props.dispatch({
          type: "CHANGES_STATUS",
          questionstatus: [...questionStatus],
        });
      } else {
        let questionStatus = props.state.questionstatus;
        questionStatus[ind].status_number = 5;
        props.dispatch({
          type: "CHANGES_STATUS",
          questionstatus: [...questionStatus],
        });
      }
      await saveAndNext(props);
      if (props.state.activeQuesInd + 1 < props.state.ques_data.length) {
        await props.dispatch({
          type: "ACTIVE_QUESTION_NUMBER",
          activeQuesInd: props.state.activeQuesInd + 1,
        });
      }
    }
  };

  const ClearResponse = () => {
    if (props.activeQuesData.ques_type_id != 2) {
      let arr = Array.isArray(props.state.answers_json)
        ? props.state.answers_json
        : props.state.answers_json.split(",");
      let ind = props.state.activeQuesInd;
      arr[ind] = "-1";
      props.dispatch({ type: "SET_ANSWER", answers_json: [...arr] });
      let questionStatus = props.state.questionstatus;
      questionStatus[ind].status_number = 2;
      props.dispatch({
        type: "CHANGES_STATUS",
        questionstatus: [...questionStatus],
      });
      saveAndNext(props);
    } else {
      let subIndex = Number(
        props.state.answers_json[props.state.activeQuesInd].split("_").pop()
      );
      if (props.state.subjective_answer[subIndex] != "-1") {
        let arr = props.state.subjective_answer;
        let ind = props.state.activeQuesInd;
        arr[
          Number(
            props.state.answers_json[props.state.activeQuesInd].split("_").pop()
          )
        ] = "-1";
        props.dispatch({
          type: "SUBJECTIVE_ANSWERS",
          subjective_answer: [...arr],
        });
        let questionStatus = props.state.questionstatus;
        questionStatus[ind].status_number = 2;
        props.dispatch({
          type: "CHANGES_STATUS",
          questionstatus: [...questionStatus],
        });
        saveAndNext(props);
      }
    }
  };
  const onPreview = () => {
    if (
      props?.state?.test_data?.is_neet_test == 1 &&
      props?.state?.test_data?.is_sec_time == 1
    ) {
      if (
        "" +
          props?.state?.ques_data[props.state?.activeQuesInd - 1]?.subject_id +
          "#" +
          props?.state?.ques_data[props.state?.activeQuesInd - 1]?.section_id ==
        props?.state?.selectedSection
      ) {
        props.dispatch({
          type: "ACTIVE_QUESTION_NUMBER",
          activeQuesInd: props.state.activeQuesInd - 1,
        });
      }
    } else {
      props.dispatch({
        type: "ACTIVE_QUESTION_NUMBER",
        activeQuesInd: props.state.activeQuesInd - 1,
      });
    }
  };
  return (
    <>
      {show && (
        <Confirm
          is_test_submit_status={
            props.testPanelData.test_settings.is_test_submit_status
          }
          setShow={setShow}
        />
      )}
      {showMadetry && <MandatoryQuestion setshowMadetry={setshowMadetry} />}
      <div
        className={
          props.showDrop == true
            ? width > 870
              ? "questionfield"
              : "hideField"
            : "questionfield"
        }
        style={{
          width: props.drower === false ? "100%" : "calc(100% - 364px)",
        }}
      >
        {/* <div className="questionheader"> */}
        <div
          style={{
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginRight: "12px",
            }}
          >
            <Slider
              subjects_data={props.testPanelData.subjects_data}
              subjectid={subjectid}
              setSubjectId={setSubjectId}
              is_neet_test={props?.state?.test_data?.is_neet_test}
              is_sec_time={props?.state?.test_data?.is_sec_time}
            />

            {props.state.test_data.is_multi_lingual == 1 && (
              <div className="subjectSelected">
                <img src={changeLanguage} height={24} width={24} />
                <select
                  className="langOption"
                  onChange={(e) => props.changeLanguage(e)}
                >
                  {props.state.test_language.map((item, index) => (
                    <option value={item.id}>{item.title}</option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <div className="topaddbutton">
            <button
              style={{
                background: "none",
                outline: "none",
                border: "none",
                color: "green",
              }}
            >
              Clear Response
            </button>
            <button
              style={{
                background: "none",
                outline: "none",
                border: "none",
                color: "green",
              }}
            >
              Mark For Review
            </button>
          </div>
          <div className="questionoptionfield">
            {props.activeQuesData && (
              <QuestionList
                activeQuesData={props.activeQuesData}
                testPanelData={props.testPanelData}
              />
            )}
          </div>
        </div>
        <div className="questionfooter">
          <div>
            {props.state.activeQuesInd > 0 &&
              props.testPanelData.test_settings.is_allow_back_button == 1 && (
                <button
                  className="clearresponsebtn"
                  onClick={() => onPreview()}
                >
                  Previous
                </button>
              )}
            <button
              className="clearresponsebtn"
              onClick={() => ClearResponse()}
            >
              Clear Response
            </button>
            <button className="markforreview" onClick={() => MarkedForReview()}>
              Mark for Review & Next
            </button>
            {props.state.activeQuesInd + 1 < props.state.ques_data.length && (
              <button className="saveandnext" onClick={() => SavendNext()}>
                Save & Next
              </button>
            )}
            {props.state.activeQuesInd + 1 === props.state.ques_data.length && (
              <button className="saveandnext" onClick={() => Save()}>
                Save
              </button>
            )}
            <button className="showsubmitbutton" onClick={() => setShow(true)}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(QuestionPanel);
